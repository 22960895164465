import * as React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Relationship} from "../GiftFormValues";

const RelationshipForm: React.FC<{ updateValue: (value: string) => void, relationship: string }> = (props) => {

    return (
        <>
            <Typography variant="h6" gutterBottom={true}>
                In welcher Beziehung/Verwandschaftsgrad stehst du zu dem Empfänger des Geschenkes?
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Beziehungsgrad</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.relationship}
                    label="Beziehungsgrad"
                    onChange={(event: SelectChangeEvent) => {
                        props.updateValue(event.target.value)
                    }}
                >
                    {
                        Object.entries(Relationship).map((occasion) => {
                            return <MenuItem key={occasion[0]} value={occasion[0]}>{occasion[1]}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </>
    )
}

export default RelationshipForm;
