import ReactGA from "react-ga4";

export const FORM_SUBMIT = "submitForm";
export const FORM_SUBMIT_ERROR = "submitFormError";
export const CLICK_AMAZON_LINK = "clickAmazonLink";
export const NEW_SEARCH = "newSearch";
export const FORM_CLICK_FIELD = "clickFormField";
export const EVENT_SELECT_CONTENT = "select_content";
export const EVENT_PURCHASE = "purchase";
export const EVENT_SELECT_ITEM = "select_item";
export const EVENT_SEARCH = "search";

// https://developers.google.com/tag-platform/devguides/events
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=14849041441127073139-EU&client_type=gtag#select_content
const useAnalyticsEventTracker = (category = "Blog category") => {
    const eventTracker = (action = "test action", label = "test label") => {
        ReactGA.event({category, action, label});
    }
    return eventTracker;
}
export const useRecommendedAnalyticsEventTracker = () => {
    const recommendedEventTracker = (action: string, params: any) => {
        ReactGA.event(action, params);
    }
    return recommendedEventTracker;
}


export default useAnalyticsEventTracker;