import * as React from 'react';
import GenderComponent from "./forms/GenderComponent";
import GiftFormValues, {GetEnumKeyByEnumValue, Occasion, Relationship} from "./GiftFormValues";
import AgeForm from "./forms/AgeComponent";
import Button from "@mui/material/Button";
import {SubmitFunction} from "./GiftPage";
import {SelectChangeEvent} from "@mui/material";
import {Theme, useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import {FORM_CLICK_FIELD, useRecommendedAnalyticsEventTracker} from "./GoogleAnalytics";
import RelationshipComponent from "./forms/RelationshipComponent";
import OccasionComponent from "./forms/OccasionComponent";
import HobbiesComponent from "./forms/HobbiesComponent";


interface GiftFormProps {
    onSubmit: SubmitFunction
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const top100Films = [
    {title: 'The Shawshank Redemption', year: 1994},
    {title: 'The Godfather', year: 1972},
    {title: 'The Godfather: Part II', year: 1974},
    {title: 'The Dark Knight', year: 2008},
    {title: '12 Angry Men', year: 1957},
    {title: "Schindler's List", year: 1993},
    {title: 'Pulp Fiction', year: 1994}]

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const GiftForm = (props: GiftFormProps) => {

    const recommendedEventTracker = useRecommendedAnalyticsEventTracker();
    const theme = useTheme();

    const [giftForm, setGiftForm] = React.useState<GiftFormValues>({
        age: 20,
        gender: 'male',
        relationship: GetEnumKeyByEnumValue(Relationship, Relationship.PARTNER),
        occasion: GetEnumKeyByEnumValue(Occasion, Occasion.BIRTHDAY),
        hobbies: []
    });

    const updateFormValue = (value: Object) => {
        let keys = Object.keys(value);
        if (keys.length > 0) {
            recommendedEventTracker(FORM_CLICK_FIELD, {
                "field_name": keys[0],
                "field_value": value[keys[0] as keyof typeof value]
            })
        } else {
            recommendedEventTracker(FORM_CLICK_FIELD, {
                "field_name": keys[0],
                "field_value": "unkown"
            })
        }
        let tmpValues = {...giftForm, ...value};
        setGiftForm(tmpValues);
    }

    const onSubmit = () => {
        props.onSubmit(giftForm)
    }

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: {value},
        } = event;
        updateFormValue(
            {hobbies: typeof value === 'string' ? value.split(',') : value}
        );
    };

    return (
        <Grid container rowSpacing={5}>

            <Grid xs={12}>
                <GenderComponent updateValue={(gender) => {
                    updateFormValue({gender: gender})
                }}
                                 gender={giftForm.gender}/>
            </Grid>

            <Grid xs={12}>
                <AgeForm updateValue={(age) => {
                    updateFormValue({age: age})
                }} age={giftForm.age}/>
            </Grid>

            <Grid xs={12}>
                <RelationshipComponent updateValue={(relationship: string) => {
                    updateFormValue({relationship: relationship as keyof Relationship});
                }} relationship={giftForm.relationship}/>
            </Grid>
            <Grid xs={12}>
                <OccasionComponent updateValue={(occasion: string) => {
                    updateFormValue({occasion: occasion});
                }} occasion={giftForm.occasion}/>
            </Grid>
            <Grid xs={12}>
                <HobbiesComponent updateValue={(hobbies: string[]) => {
                    updateFormValue(
                        {hobbies: hobbies}
                    );
                }} hobbies={giftForm.hobbies}/>
            </Grid>
            <Grid xs={12}>
                <Button variant="contained" onClick={onSubmit}>Zeig mir die Ideen!</Button>
            </Grid>
        </Grid>
    );
}

export default GiftForm;