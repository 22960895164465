import * as React from 'react';
import {useEffect} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import Menu from "./Menu";
import {Container} from "@mui/material";
import ReactGA from "react-ga4";

const RootLayout = () => {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname + location.search, title: location.pathname});
    }, [location]);

    return (
        <>
            <Menu/>
            <Container maxWidth="lg">
                <Outlet/>
            </Container>
        </>
    );
}

export default RootLayout;