interface GiftFormValues {
    age: number
    gender: string
    occasion: string
    relationship: string
    hobbies: string[]
}

export default GiftFormValues

// https://github.com/tkrajina/typescriptify-golang-structs
export enum Occasion {
    BIRTHDAY = "Geburtstag",
    BAPTISM = "Taufe",
    CHRISTMAS = "Weihnachten",
    SECRET_SANTA = "Wichteln",
    NAMEDAY = "Namenstag",
    MOTHERSDAY = "Muttertag",
}

export enum Relationship {
    PARTNER = "Partner",
    FRIENDS = "Enger Freund",
    ACQUANTANCE = "Bekannter",
    BROTHER = "Bruder",
    SISTER = "Schwester",
    FAMILY = "Geschwister",
    MOTHER = "Mutter",
    FATHER = "Vater",
}

export enum Hobbies {
    D3_DRUCK = "3D-Druck",
    ANGELN = "Angeln",
    SAMMELN_VON_ANTIQUITAETEN = "Sammeln von Antiquitäten",
    APP_ENTWICKLUNG_PROGRAMMIEREN_LERNEN = "App-Entwicklung – Programmieren lernen",
    AUGMENTED_REALITY = "Augmented Reality",
    BACKEN = "Backen",
    BASEBALL = "Baseball",
    BLOGGEN = "Bloggen",
    BRETTSPIELE_SPIELEN = "Brettspiele spielen",
    BUNGEE_JUMPING = "Bungee-Jumping",
    KALLIGRAPHIE = "Kalligraphie",
    CAMPING = "Camping",
    COSPLAY = "Cosplay",
    DIY_PROJEKTE = "DIY-Projekte",
    DROHNENFLIEGEN = "Drohnenfliegen",
    E_SPORTS = "E-Sports",
    EISLAUFEN = "Eislaufen",
    FALLSCHIRMSPRINGEN = "Fallschirmspringen",
    FILME_DREHEN = "Filme drehen",
    FILME_SCHAUEN = "Filme schauen",
    FOTOGRAFIEREN = "Fotografieren",
    FREMDSPRACHEN_LERNEN = "Fremdsprachen lernen",
    FUSSBALL = "Fußball",
    GAERTNERN = "Gärtnern",
    GAMING = "Gaming",
    GOLF = "Golf",
    HOCKEY = "Hockey",
    HUBSCHRAUBERFLIEGEN = "Hubschrauberfliegen",
    INLINESKATEN = "Inlineskaten",
    JAGEN = "Jagen",
    JONGLIEREN = "Jonglieren",
    KARTENSPIELE_SPIELEN = "Kartenspiele spielen",
    KAMPFSPORT = "Kampfsport",
    KERZEN_GIESSEN = "Kerzen gießen",
    KLETTERN = "Klettern",
    KOCHEN = "Kochen",
    KONZERTE_FESTIVALS_BESUCHEN = "Konzerte oder Festivals besuchen",
    KRAFTTRAINING_FITNESS = "Krafttraining/Fitness",
    KREUZWORTRAETZEL = "Kreuzworträtzel",
    KUENSTLICHE_INTELLIGENZ = "Künstliche Intelligenz",
    LAUFEN = "Laufen",
    LARP = "LARP (Live Action Role Playing)",
    LANGLAUFEN = "Langlaufen",
    LETTERING = "Lettering",
    LESEN = "Lesen",
    MALEN = "Malen",
    MALBUECHER = "Malbücher – Ausmalen von Malvorlagen",
    MEDITIEREN = "Meditieren",
    MODELLBAU = "Modellbau",
    MUSIK_HOEREN = "Musik hören",
    MUSIK_MACHEN = "Musik machen",
    NAEHEN = "Nähen",
    ONLINE_SPIELE_SPIELEN = "Online-Spiele spielen",
    ORIGAMI = "Origami",
    PARAGLIDING = "Paragliding",
    PARKOUR = "Parkour",
    PEN_PAPER_ROLLENSPIELE = "Pen & Paper Rollenspiele",
    PODCASTING = "Podcasting",
    PUZZLE_LOESEN = "Puzzle lösen",
    RAFTING = "Rafting",
    RADFAHREN = "Radfahren",
    REISEN = "Reisen",
    REITEN = "Reiten",
    ROBOTIK = "Robotik",
    RAETSEL_LOESEN = "Rätsel lösen",
    ROLLSCHUHLAUFEN = "Rollschuhlaufen",
    RUGBY = "Rugby",
    INSTRUMENT_SPIELEN = "Instrument spielen",
    SCHACH_SPIELEN = "Schach spielen",
    SCHMUCKHERSTELLUNG = "Schmuckherstellung",
    SCHREIBEN = "Schreiben",
    SCHWIMMEN = "Schwimmen",
    SCRAPBOOKING = "Scrapbooking",
    SEIFENHERSTELLUNG = "Seifenherstellung",
    SEGELN = "Segeln",
    SKATEBOARDEN = "Skateboarden",
    SINGEN = "Singen",
    SKIFAHREN = "Skifahren",
    SNOWBOARDEN = "Snowboarden",
    SOCIAL_MEDIA_MARKETING = "Social-Media-Marketing",
    SUDOKU_LOESEN = "Sudoku lösen",
    SURFEN = "Surfen",
    TANZEN = "Tanzen",
    TAUCHEN = "Tauchen",
    TENNIS = "Tennis",
    TISCHTENNIS = "Tischtennis",
    THEATER_BESUCHEN = "Theater besuchen",
    TOEPFERN = "Töpfern",
    UPCYCLING = "Upcycling",
    VIRTUELLE_REALITAET = "Virtuelle Realität",
    VOLLEYBALL = "Volleyball",
    VLOGGING = "Vlogging",
    WANDERN = "Wandern",
    WEBDESIGN = "Webdesign",
    YOGA = "Yoga",
    ZEICHENSPRACHE = "Zeichensprache",
    ZEICHNEN = "Zeichnen",
    ZAUBERN = "Zaubern"
}

export function GetEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : '';
}