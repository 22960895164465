import * as React from 'react';
import {SyntheticEvent} from 'react';
import {Autocomplete, AutocompleteChangeReason, FormControl, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Hobbies} from "../GiftFormValues";

const HobbiesForm: React.FC<{ updateValue: (value: string[]) => void, hobbies: string[] }> = (props) => {

    return (
        <>
            <Typography variant="h6" gutterBottom={true}>
                Was macht der/die Beschenkte gerne?
            </Typography>
            <FormControl fullWidth>

                <Autocomplete
                    multiple
                    // freeSolo
                    id="hobbies"
                    onChange={(event: SyntheticEvent, value: [string, Hobbies][], reason: AutocompleteChangeReason) => {
                        let hobbies = value.map(val => val[0]);
                        props.updateValue(hobbies)
                    }}
                    isOptionEqualToValue={(option: [string, Hobbies], value: [string, Hobbies]): boolean => {
                        return option[0] == value[0] && option[1] == value[1]
                    }}
                    options={Object.entries(Hobbies)}
                    getOptionLabel={(option: [string, Hobbies]) => {
                        let optionElement = option[1];
                        if (typeof optionElement == "string") {
                            return optionElement
                        }
                        throw new Error("option invalid " + option)
                    }}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Hobbies"
                            placeholder="Hobbies"
                        />
                    )}
                />

            </FormControl>
        </>
    )
}

export default HobbiesForm;
