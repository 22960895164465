import * as React from 'react';
import GiftSuggestions from "./GiftSuggestions";
import GiftForm from "./GiftForm";
import GiftFormValues from "./GiftFormValues";
import api from "../api/api";
import {GiftResponse} from "../api/GiftResponse";
import {CircularProgress, Container, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import useAnalyticsEventTracker, {FORM_SUBMIT, FORM_SUBMIT_ERROR, NEW_SEARCH} from "./GoogleAnalytics";

export type SubmitFunction = (values: GiftFormValues) => void

const GiftPage = () => {
    const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
    const [suggestions, setSuggestions] = React.useState<GiftResponse[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);

    const eventTracker = useAnalyticsEventTracker("GiftPage");

    const onSubmit = (formValues: GiftFormValues) => {
        eventTracker(FORM_SUBMIT)

        setIsLoading(true)
        api.getGiftSuggestions(formValues).then(response => {
            setIsLoading(false)
            setShowSuggestions(true);
            if (response.status >= 200 && response.status < 300) {
                return response.data
            } else {
                setShowError(true)
                eventTracker(FORM_SUBMIT_ERROR)

                let errorMessage = "Server can't be reached!";
                if (response.status) {
                    errorMessage += " Status:" + response.status;
                }
                throw new Error(errorMessage)
            }
        }).then((data: GiftResponse[]) => {
            setSuggestions(data)
        }).catch((error) => {
            setShowError(true)
            setIsLoading(false)
            eventTracker(FORM_SUBMIT_ERROR)
        })
    }

    const newSearch = () => {
        setShowSuggestions(false)
        setSuggestions([])
        setShowError(false)
        setIsLoading(false)
        eventTracker(NEW_SEARCH)
    }

    var errorView = () => {
        return (<Grid xs={12} sx={{pt: 5}}>
                <h3>Oooops, hier ist etwas schiefgelaufen.</h3>
                <Button variant="contained" onClick={newSearch}>Neue Suche starten</Button>
            </Grid>
        )
    }
    return (
        <>
            <Box sx={{p: {xs: 1, md: 5}}}>
                <Container maxWidth="md">
                    <Grid2 container rowSpacing={5}>
                        <Grid2 xs={12} md={3} display="flex" alignItems="center" justifyContent="center">
                            <Box
                                component="img"
                                sx={{
                                    height: 80,
                                    // width: 350,
                                    maxHeight: {xs: 233, md: 167},
                                    // maxWidth: { xs: 350, md: 250 },
                                }}
                                alt="The house from the offer."
                                src="/gift.png"
                            />
                        </Grid2>
                        <Grid2 xs={12} md={9} display="flex" alignItems="center">
                            <Typography variant="h3" component="h1" align="center">
                                Geschenke für jeden Anlass!
                            </Typography>
                        </Grid2>
                        {!showSuggestions && !isLoading &&
                        <Grid2 xs={12}>
                            <Typography variant="h5" gutterBottom align="center" paragraph={true}>
                                Du bist auf der Suche nach einer Geschenkidee? Teile uns ein paar Eckdaten über die zu
                                beschenkende Person mit und wir liefern dir Inspirationen!
                            </Typography>
                        </Grid2>
                        }
                    </Grid2>
                </Container>
            </Box>
            <Paper square={false} sx={{p: {xs: 1, md: 5}}}>
                {!showError && !showSuggestions && !isLoading && <GiftForm onSubmit={onSubmit}/>}
                {isLoading && !showError &&
                    <Box sx={{display: 'flex', justifyContent: 'center'}}><CircularProgress size="6rem"/> </Box>}
                {showSuggestions && !isLoading && <GiftSuggestions suggestions={suggestions} newSearch={newSearch}/>}
                {showError && !isLoading && errorView()}
            </Paper>
        </>
    )
        ;
}

export default GiftPage;