import * as React from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import Typography from "@mui/material/Typography";

const GenderComponent: React.FC<{ updateValue: (value: string) => void, gender: string }> = (props) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.updateValue((event.target as HTMLInputElement).value);
    };

    return (
        <FormControl>
            <Typography variant="h6" gutterBottom={true}>
                Ist der zu beschenkende ein Mann oder eine Frau?
            </Typography>
            <RadioGroup row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={props.gender}
                onChange={handleChange}
            >
                <FormControlLabel value="female" control={<Radio/>} label="Frau"/>
                <FormControlLabel value="male" control={<Radio/>} label="Mann"/>
            </RadioGroup>
        </FormControl>
    )
}

export default GenderComponent;
