import * as React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Occasion} from "../GiftFormValues";

const OccasionComponent: React.FC<{ updateValue: (value: string) => void, occasion: string }> = (props) => {

    return (
        <>
            <Typography variant="h6" gutterBottom={true}>
                Für welchen Anlass soll das Geschenk sein?
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Anlass</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.occasion}
                    label="Occasion"
                    onChange={(event: SelectChangeEvent) => {
                        props.updateValue(event.target.value as string);
                    }}
                >
                    {
                        Object.entries(Occasion).map((occasion) => {
                            return <MenuItem key={occasion[0]} value={occasion[0]}>{occasion[1]}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </>
    )
}

export default OccasionComponent;
