import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ImpressumPage from "./components/ImpressumPage";
import RootLayout from "./components/RootLayout";
import GiftPage from "./components/GiftPage";
import {createTheme, ThemeProvider} from "@mui/material";
import ReactGA from 'react-ga4';
import {Helmet} from "react-helmet";


let browserRouter = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout/>,
        children: [
            {
                path: '/',
                element: <GiftPage/>
            },
            {
                path: '/impressum',
                element: <ImpressumPage/>
            }]
    },
]);


const theme = createTheme({
    palette: {
        primary: {
            light: "#92FF00",
            main: "#00796B",
        },
    },
});

ReactGA.initialize('G-6FV26RBHSX', {
    gaOptions: {
        debug_mode: false,
    }
});

function App() {

    // useEffect(() => {
    //     document.title = 'Geschenkideen';
    // }, []);


    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Geschenkomat</title>
                <meta name="description" content="Geschenkideen für dich"/>
            </Helmet>
            <RouterProvider router={browserRouter}/>
        </ThemeProvider>
    )
}

export default App;
