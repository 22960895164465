import axios from 'axios'
import GiftFormValues from "../components/GiftFormValues";
import {GiftResponseImpl} from "./GiftResponse";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default {
    getGiftSuggestions: (formValues: GiftFormValues) => {
        return instance.request<GiftResponseImpl[]>({
            'method': 'POST',
            'url': '/search-gifts',
            'data': formValues
        })
    },

    // instance({
    //     'method': 'POST',
    //     'url': '/v1/ebay',
    //     'data': formValues
    //     ,
    //     transformResponse: (data) => r.data
    //     // transformResponse: [function (data) {
    //     //     Do whatever you want to transform the data
    //     // console.log('Transforming data...', data)
    //     // const json = JSON.parse(data)
    //
    //     // list of nested object keys
    //     // const dates = Object.keys(json['findItemsByKeywordsResponse'])
    //
    //     // data = {
    //     //     dates
    //     // }
    //
    //     // return data;
    //     // }],
    // })
    //,
    postData: () =>
        instance({
            'method': 'POST',
            'url': '/api',
            'data': {
                'item1': 'data1',
                'item2': 'item2'
            },
            'headers': {
                'content-type': 'application/json' // override instance defaults
            },
        })
}