import * as React from 'react';
import {FormControl, Input, Slider} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";


const GenderForm: React.FC<{ updateValue: (value: number) => void, age: number }> = (props) => {


    const [value, setValue] = React.useState(30);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        // setValue(newValue as number);
        if (typeof newValue == "number") {
            props.updateValue(newValue)
        } else {
            throw new Error('Unexpected age type');
        }

    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let age = event.target.value === '' ? 0 : Number(event.target.value);
        props.updateValue(age)
    };

    const handleBlur = () => {
        if (value < 0) {
            props.updateValue(0);
        } else if (value > 100) {
            props.updateValue(100);
        }
    };
    return (
        <FormControl fullWidth>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Typography variant="h6" gutterBottom={true}>
                        Alter
                    </Typography>
                </Grid>

                <Grid xs={2} md={1}>
                    <Input
                        value={props.age}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 5,
                            min: 0,
                            max: 100,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
                <Grid xs={10} md={11}>
                    <Slider
                        value={Number(props.age)}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                    />
                </Grid>
            </Grid>
        </FormControl>
    )
}

export default GenderForm;
