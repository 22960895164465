import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link as RouterLink} from "react-router-dom";
import {MenuItem} from "@mui/material";
import Link from "@mui/material/Link";
import GiftIcon from '@mui/icons-material/CardGiftcard';


const Menu = () => {
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>

                    <GiftIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                    <MenuItem sx={{flexGrow: 1}}>
                        <Typography variant="h6" color="common.white">
                            <Link to="/" color="common.white" component={RouterLink}
                                  sx={{textDecoration: 'none'}} reloadDocument={true}>Geschenkideen</Link>
                        </Typography>
                    </MenuItem>
                    <MenuItem>
                        <Typography variant="body1">
                            <Link to="/impressum" color="common.white" component={RouterLink}
                                  sx={{textDecoration: 'none'}}>Impressum</Link>
                        </Typography>
                    </MenuItem>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Menu;